import React, { useEffect, useState, useRef } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import Nav from 'react-bootstrap/Nav';

import RESLogo from '../static/images/logo.svg'
import RESLogo_PNG from '../static/images/logo.png'
import RESLogo_SVG from '../static/images/res-logo.svg'
import Hamburger from '../static/images/ham.svg'

export default function Header() {
    const [menuToggle,setMenuToggle] = useState(false);
    const [isScrolled,setIsScrolled] = useState(false);
    const [scrollPosition, setScrollPosition] = useState();

    var headerOffset = 80;

    useEffect(()=>{
        
    },[])
    
    const handleMenu = (props)=> {
        if(menuToggle) {
            setMenuToggle(false)
        } else {
            setMenuToggle(true)
            setIsScrolled(false)
        }
    }

    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    }

    const handleMain = () => {
        setMenuToggle(false)
    }

    useEffect(() => {
        if(scrollPosition>200 && !menuToggle) {
            setIsScrolled(true)
        } else {
            setIsScrolled(false)
        }
    },[scrollPosition,menuToggle])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        window.location.hash = window.decodeURIComponent(window.location.hash);
        const scrollToAnchor = () => {
            const hashParts = window.location.hash.split('#');
            const hash = hashParts.slice(-1)[0];
            if(hash) {
                setTimeout(()=>{
                    var elementPosition = document.querySelector(`#${hash}`).getBoundingClientRect().top;
                    var offsetPosition = elementPosition + window.scrollY - headerOffset;
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });
                }, 300)
            } else {
                setTimeout(()=>{
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                },100)
            }
            
        };
        scrollToAnchor();
        window.onhashchange = scrollToAnchor;

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <div id='site-header' className={isScrolled?'scroll':''}>
                <div className='site-logo'>
                    <img src={RESLogo_SVG} alt='RES Logo'/>
                </div>
                <div className='site-title'>
                    <Nav 
                        activeKey={window.location.pathname} 
                        // defaultActiveKey={`${process.env.PUBLIC_URL}`}
                        // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
                    >
                        <Nav.Item>
                            <Nav.Link href={`${process.env.PUBLIC_URL}/`}>Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href={`${process.env.PUBLIC_URL}/about`}>About RES</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href={`${process.env.PUBLIC_URL}/Applications`}>Applications</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                            <Nav.Link href={`${process.env.PUBLIC_URL}/contact`}>Get in Touch</Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                    <img className='ham-menu' src={Hamburger} alt="Menu" onClick={handleMenu}/>
                </div>
            </div>

            <div id='site-menu' className={menuToggle?'open':'close'}>
                <div>
                    <h6><Link reloadDocument onClick={handleMain} to={`${process.env.PUBLIC_URL}`}>Home</Link></h6>
                    <ul>
                        <li><Link onClick={handleMain} to={`${process.env.PUBLIC_URL}/#HowWeDoIt`}>How We Do It</Link></li>
                        <li><Link onClick={handleMain} to={`${process.env.PUBLIC_URL}/#currentLiterature`}>Current Literature</Link></li>
                        <li><Link onClick={handleMain} to={`${process.env.PUBLIC_URL}/Applications`}>Applications</Link></li>
                    </ul>
                </div>
                <div>
                    <h6><Link reloadDocument onClick={handleMain} to={`${process.env.PUBLIC_URL}/about`}>About RES</Link></h6>
                    <ul>
                        <li><Link onClick={handleMain} to={`${process.env.PUBLIC_URL}/about/#vision`}>Vision</Link></li>
                        <li><Link onClick={handleMain} to={`${process.env.PUBLIC_URL}/about/#impact`}>Impact</Link></li>
                        <li><Link onClick={handleMain} to={`${process.env.PUBLIC_URL}/about/#publications`}>Patents & Publications</Link></li>
                    </ul>
                </div>
                <div>
                    <div>
                        <h6 className='text-info mb-0'>Office</h6>
                        <p>6143 Wigeon Court | Dublin Ohio 43017</p>
                    </div>
                    <div>
                        <h6 className='text-info mb-0'>Call</h6>
                        <p>614-284-7432</p>
                    </div>
                    <div>
                        <h6 className='text-info mb-0'>Email</h6>
                        <p><a href='mailto:yedavallirk@gmail.com' className='text-secondary'>yedavallirk@gmail.com</a></p>
                    </div>
                </div>
            </div>
        </>
    )
}