import React, { useEffect, useState, useRef } from 'react';
import 'aos/dist/aos.css';
import 'animate.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomSlider from "../components/custom.slider";

const appImgs = [
    {
      imgURL:
        "/images/app-00.png",
      imgAlt: "img-1"
    },
    {
      imgURL:
        "/images/app-01.png",
      imgAlt: "img-2"
    },
    {
      imgURL:
        "/images/app-02.png",
      imgAlt: "img-3"
    },
    {
      imgURL:
        "/images/app-03.png",
      imgAlt: "img-4"
    },
    {
      imgURL:
        "/images/app-04.png",
      imgAlt: "img-5"
    }
];
const delay = 2500;

export default function Applications() {

    const [allApps,setAllApps] =  useState([]);

    useEffect(() => {
        // fetch('http://localhost/res-bk/getApplications.php')
        fetch('https://robustengsys.com/getApplications.php')
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setAllApps(data)
            });
    }, []);
        
    console.log(allApps)

    return (
        <>
            <div className="container-fluid fullFold homeMain">
                <Row>
                    <Col className='col-12 col-lg-6 app--bgg'>
                        <img className='app--bg' src={`${process.env.PUBLIC_URL}`+ '/images/app-bg.png'} alt='alt' />
                        <CustomSlider>
                            {appImgs.map((image, index) => {
                                // return <img key={index} src={image.imgURL} alt={image.imgAlt} />;
                                return <img key={index} src={`${process.env.PUBLIC_URL}`+image.imgURL} alt={image.imgAlt} />;
                            })}
                        </CustomSlider>
                    </Col>
                    <Col className='col-12 col-lg-6 align-self-center'>
                        <div data-aos='fade-down'>
                            <h1 className='text-primary text-start'>Applications</h1>
                        </div>
                        <h6 className='text-start' data-aos='fade-up' data-aos-delay="200">Uncertainty in control systems refers to the inherent unpredictability and variability that exists within the system, making it challenging to accurately predict and control its behavior.</h6>
                        <div className='scrollAlt animate__animated animate__bounce animate__delay-3s animate__repeat-3 animate__slow'>
                            <i className="bi bi-mouse"></i>
                        </div>
                    </Col>
                </Row>
            </div>

            <div id="allApplications" className="container-fluid fullFold bg-secondary text-light">
                <div className='container-fluid'>
                    <div className='container py-5'>
                        <h4 className='text-center mb-5'>Mission Critical Applications</h4>
                        {allApps.map((apps,idx)=>{
                            return (
                                <Row key={idx}>
                                    <Col className={(idx%2)?'col-12 col-lg-6 align-self-center text-lg-end':'col-12 col-lg-6 align-self-center order-lg-1 text-start'} data-aos={(idx%2)?'fade-left':'fade-right'} data-aos-delay="250">
                                        <h5>{apps.title}</h5>
                                        <p className='skewBG inverse bg-white py-0 text-dark d-inline-block'>
                                            <span>{apps.category}</span>
                                        </p>
                                        <p className='text-primary'>{apps.description}</p>
                                    </Col>
                                    <Col className={'col-12 col-lg-6'} data-aos={(idx%2)?'fade-right':'fade-left'} data-aos-delay="250">
                                        <img className='mainApps' src={process.env.PUBLIC_URL+ '/images/' + apps.image + '.png'} alt={apps.image} />
                                    </Col>
                                </Row>
                            )
                        })}
                    </div>
                </div>

            </div>
        </>
    )
}